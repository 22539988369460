<template>
    <div id="nav">
        <router-link to="/">Queue</router-link>
        <router-view/>
    </div>
</template>

<style>
    /*noinspection CssUnusedSymbol*/
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    #nav {
        padding: 30px;
    }

    #nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    /*noinspection CssUnusedSymbol*/
    #nav a.router-link-exact-active {
        color: #42b983;
    }
</style>
