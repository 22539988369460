import Vue, { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import BootstrapVue from "bootstrap-vue";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

axios.defaults.baseURL = "https://toptier-doc.scrx.io";
// axios.defaults.baseURL = "https://localhost:7296";
axios.defaults.headers.common = { "Content-Type": "application/json", };
axios.defaults.withCredentials = true;

const cognitoLoginUrl = "https://providen-doctor-app.auth.ca-central-1.amazoncognito.com";
const clientId = "2a8st8ctkettof4uhpopt8sk03";

const sha256 = async (str: string): Promise<ArrayBuffer> => {
    return await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));
};

const generateNonce = async (): Promise<string> => {
    const hash = await sha256(crypto.getRandomValues(new Uint32Array(4)).toString());
    // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
    const hashArray = Array.from(new Uint8Array(hash));
    return hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
};

const base64URLEncode = (str: any): string => {
    return btoa(String.fromCharCode.apply(null, ((new Uint8Array(str)) as unknown as number[])))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
};

const redirectToLogin = async (): Promise<void> => {
    const state = await generateNonce();
    const codeVerifier = await generateNonce();
    sessionStorage.setItem(`codeVerifier-${state}`, codeVerifier);
    const codeChallenge = base64URLEncode(await sha256(codeVerifier));
    window.location.assign(`${cognitoLoginUrl}/login?response_type=code&client_id=${clientId}&redirect_uri=${window.location.origin}&state=${state}&code_challenge_method=S256&code_challenge=${codeChallenge}`);
};

const errorHandler = (err: AxiosError): any => {
    if (err?.response?.status == 401) {
        localStorage.removeItem("tokens");
        const config = err.response.config;
        return fullFetch(config);
    }
    return Promise.reject(err);
};

const fetchToken = (searchParams: URLSearchParams): Promise<any> => {
    window.history.replaceState({}, document.title, "/");
    const state = searchParams.get("state");
    const codeVerifier = sessionStorage.getItem(`codeVerifier-${state}`);
    sessionStorage.removeItem(`codeVerifier-${state}`);
    return fetch(`${cognitoLoginUrl}/oauth2/token`, {
        method: "POST",
        headers: new Headers({ "content-type": "application/x-www-form-urlencoded" }),
        body: Object.entries({
            "grant_type": "authorization_code",
            "client_id": clientId,
            "code": searchParams.get("code"),
            "code_verifier": codeVerifier,
            "redirect_uri": window.location.origin,
        }).map(([k, v]) => `${k}=${v}`).join("&"),
    }).then(async res => {
        const tokens = await res.json();
        if (!res.ok) {
            throw new Error(tokens);
        }
        localStorage.setItem("tokens", JSON.stringify(tokens));

        axios.defaults.headers.Authorization = `Bearer ${tokens.access_token}`;
        return tokens;
    });
};

const fullFetch = (config: InternalAxiosRequestConfig): Promise<any> => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("code") !== null) {
        return new Promise((resolve, reject) => {
            return fetchToken(searchParams).then((tokens: any) => {
                config.headers.Authorization = `Bearer ${tokens.access_token}`;
                return resolve(config);
            }).catch(err => reject(err));
        });
    } else {
        const storedToken = localStorage.getItem("tokens");
        if (storedToken !== null) {
            const tokens = JSON.parse(storedToken);
            axios.defaults.headers.Authorization = `Bearer ${tokens.access_token}`;
            if (config && config.headers) {
                config.headers.Authorization = `Bearer ${tokens.access_token}`;
            }
            return Promise.resolve();
        } else {
            return redirectToLogin();
        }
    }
};
const requestHandler = (config: InternalAxiosRequestConfig): any => {
    if (
        !config?.headers?.Authorization ||
        config.headers.Authorization == "Bearer " ||
        config.headers.Authorization == "Bearer Unknown"
    ) {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get("code");
        if (code !== null) {
            return new Promise((resolve, reject) => {
                return fetchToken(searchParams).then((tokens: any) => {
                    config.headers.Authorization = `Bearer ${tokens.access_token}`;
                    return resolve(config);
                }).catch(err => reject(err));
            });
        } else {
            const storedToken = localStorage.getItem("tokens");
            if (storedToken !== null) {
                const tokens = JSON.parse(storedToken);
                axios.defaults.headers.Authorization = `Bearer ${tokens.access_token}`;
                if (config && config.headers) {
                    config.headers.Authorization = `Bearer ${tokens.access_token}`;
                }
            } else {
                return redirectToLogin();
            }
        }
    }
    return config;
};
// make sure we setup headers for initial requests.
axios.interceptors.request.use(requestHandler);
// make sure we setup headers for requests where token has expired, or we get a 401.
axios.interceptors.response.use(
    response => response,
    (err: AxiosError) => errorHandler(err)
);

Vue.use(BootstrapVue);
createApp(App).use(router).mount("#app");
