
export interface GSDDPatientDiagnosis {
    id: number;
    patientId: number;
    icD10Code: string;
    description: string;
    created: Date;
    createdBy: string;
    removed: Date;
    removedBy: string;
}

export interface GSDDPatientAllergy {
    patientId: number,
    allergyType: AllergyType,
    allergyId: number,
    allergyName: string,
}

export enum AllergyType {
    Class = 0,
    Ingredient = 1,
    Error = 2,
    'None Documented',
}
