
import axios from "axios";
import {Component, Ref, Vue, Watch} from "vue-facing-decorator";
import {BTable, BvTableCtxObject} from "bootstrap-vue";
import {OrderLabelData} from "@/models/OrderLabelData";
import PrescriptionDetails from "@/components/PrescriptionDetails.vue";
import {Prescription} from "@/models/Prescription";

@Component({
    components: {PrescriptionDetails},
})
export default class DoctorQueue extends Vue {
    @Ref private doctorQueueTable!: BTable;
    private selectedPrescription: Prescription | null = null;
    protected selected: any[] = [];
    private allItems: OrderLabelData[] = [];
    protected submitting: boolean = false;

    private fields = [
        {key: "selected", label: ""},
        {key: "orderId", label: "Id"},
        {key: "ndc", label: "NDC"},
        {key: "productNameShort", label: "Drug"},
        {key: "patientID", label: "Patient", formatter: this.patientFormatter},
        {key: "storeName", label: "Store"},
        {key: "rxId", label: "Rx Id"},
        {key: "dispensed", label: "Dispensed"},
        {key: "fillDate", label: "Date", formatter: this.dateFormatter},
        {key: "patientPay", label: "Patient Pay", formatter: this.currencyFormatter},
        {key: "programName", label: "Program"},
        {key: "orderDate", label: "Order Date", formatter: this.dateFormatter, sortable: true},
        {key: "details", label: ""},
    ];

    get requiredRxIds(): string[] {
        let selectedOrderIds = this.selected.map(i => i.orderId);
        console.log("selectedOrderIds: ", selectedOrderIds);
        let selectedRxIds = this.selected.map(i => i.rxId);
        console.log("selectedRxIds: ", selectedRxIds);
        let items = this.allItems;
        // console.log("items: ", items);

        let requiredRxIds = items.filter(i => selectedOrderIds.includes(i.orderId)).map(i => i.rxId);
        return requiredRxIds;
    }
    get selectedRxIds(): string[] {
        let selectedRxIds = this.selected.map(i => i.rxId);
        console.log("selectedRxIds: ", selectedRxIds);
        return selectedRxIds;
    }
    get neededRxIds(): string[] {
        let neededRxIds = [];
        for (const requiredRxId of this.requiredRxIds) {
            if (!this.selectedRxIds.includes(requiredRxId)){
                neededRxIds.push(requiredRxId);
            }
        }
        return neededRxIds;
    }
    get disableSubmit(): boolean {
        return this.selected.length === 0 || this.submitting || this.neededRxIds.length > 0;
    }

    get selectedPrescriptionExists(): boolean {
        return this.selectedPrescription !== null;
    }

    // used after modal is hidden to reset selectedPrescription
    set selectedPrescriptionExists(value: boolean) {
        if (!value) {
            this.selectedPrescription = null;
        }
    }

    async itemProvider(ctx: BvTableCtxObject): Promise<any[] | []> {
        try {
            const url = ctx.apiUrl + "";
            let resp = await axios.get<OrderLabelData[]>(url);
            this.allItems = resp.data;
            return resp.data;
        } catch (err) {
            console.log("err: ", err);
            return [];
        }
    }

    patientFormatter(value: number, key: string, item: OrderLabelData): string {
        return `${item.patientLastName}, ${item.patientFirstName}`;
    }

    dateFormatter(value: string, _key: string, _item: OrderLabelData): string {
        if (!value) return "";
        const date = Date.parse(value);
        return Intl.DateTimeFormat().format(date);
    }

    currencyFormatter(value: number, _key: string, _item: OrderLabelData): string {
        if (isNaN(value)) return "";
        return `$${value.toFixed(2)}`;
    }

    selectAll(): void {
        if (this.selected.length == 0) {
            this.selected = this.allItems;
        } else {
            this.selected = [];
        }
    }

    @Watch('selected')
    selectedUpdate(newValue: any[], _oldValue: any[]): void {
        console.log("selected = ", newValue);
        const selectedIds = newValue.map(i => i.orderId);
        console.log("selectedIds = ", selectedIds);
    }

    async submitScripts(): Promise<void> {
        this.submitting = true;
        const rxIds = this.selected.map(i => i.rxId);
        try {
            let resp = await axios.post("api/DoctorQueue/RxIds", rxIds);
            console.log("resp: ", resp);
        } catch (err) {
            console.log("err: ", err);
            let message = err?.response?.data?.message;
            if (message.length > 0) alert(err?.response?.data?.message);

        } finally {
            this.submitting = false;
            this.doctorQueueTable.refresh();
            this.selected = [];
        }
    }

    colorCodeTable(rx: OrderLabelData, type: any): string|undefined {
        if (rx && rx.rxId && this.neededRxIds.length > 0)
        {
            if (this.neededRxIds.includes(rx.rxId)) return "table-warning";
        }
        return;
    }

}
