
import {Component, VModel, Vue, Watch} from "vue-facing-decorator";
import ImageComponent from "@/components/ImageComponent.vue";
import {OrderLabelData} from "@/models/OrderLabelData";
import axios from "axios";
import {AllergyType, GSDDPatientAllergy, GSDDPatientDiagnosis} from "@/models/GSDD";
import {Patient} from "@/models/Patient";
import {BvTableFieldArray} from "bootstrap-vue";

@Component({
    components: {ImageComponent},
})
export default class PrescriptionDetails extends Vue {
    @VModel({name: 'value'}) private prescription!: OrderLabelData | null;
    protected allergies: GSDDPatientAllergy[] = [];
    protected scriptHistory: OrderLabelData[] = [];
    protected diagnoses: GSDDPatientDiagnosis[] = [];
    protected patient: Patient = new Patient();

    @Watch('prescription')
    prescriptionChange():void {
        this.allergies = [];
        this.diagnoses = [];
        this.scriptHistory = [];
        this.fetchAllergies();
    }

    mounted():void {
        this.fetchAllergies();
    }

    allergyString(allergy: GSDDPatientAllergy): string {
        if (!allergy) return "";
        return `${AllergyType[allergy.allergyType]}${allergy.allergyName.length > 0 ? ' - ' + allergy.allergyName : ''}`;
    }

    async fetchScriptHistory():Promise<void> {
        if (!this.prescription?.rxId) return;
        console.log("Fetch script history");
        try {
            let response = await axios.get<OrderLabelData[]>(`/api/DoctorQueue/History/${this.prescription?.rxId}`);
            this.scriptHistory = response.data;
        } catch (err) {
            console.log("err: ", err);
        }

    }

    async fetchAllergies(): Promise<void> {
        if (!this.prescription?.rxId) return;
        try {
            let response = await axios.get<{
                allergies: GSDDPatientAllergy[],
                diagnoses: GSDDPatientDiagnosis[],
                patient: Patient,
            }>(`/api/DoctorQueue/Details/${this.prescription?.rxId}`);
            this.diagnoses = response.data.diagnoses;
            this.allergies = response.data.allergies;
            this.patient = new Patient(response.data.patient);
            if (this.allergies.length === 0) {
                let noDocumentedAllergies: GSDDPatientAllergy = {
                    patientId: this.prescription.patientID,
                    allergyType: AllergyType["None Documented"],
                    allergyId: 0,
                    allergyName: "",
                };
                this.allergies = [noDocumentedAllergies];
            }
        } catch (err) {
            let errorAllergy: GSDDPatientAllergy = {
                allergyType: AllergyType.Error,
                allergyName: "An error occured when attempting to load known allergies",
                allergyId: 0,
                patientId: this.prescription.patientID,
            };
            this.allergies = [errorAllergy];
            console.log("err: ", err);
        }
    }

    get scriptHistoryFields(): BvTableFieldArray {
        return [
            {key: "fillDate", label: "Date", formatter: this.dateFormatter, sortable: true},
            {key: "patientID", label: "Patient", formatter: this.patientFormatter},
            {key: "ndC11", label: "NDC"},
            {key: "productNameShort", label: "Drug"},
            {key: "dispensed", label: "Dispensed"},
            {key: "storeName", label: "Store"},
            {key: "rxId", label: "Rx Id"},
        ];
    }

    get ndc(): string {
        if (!this.prescription?.ndc) return "";
        return `${this.prescription?.ndc.substr(0, 4)}-${this.prescription?.ndc.substr(4, 5)}-${this.prescription?.ndc.substr(9)}`;
    }

    get patientAge(): string {
        return `${this.patient.age} Years Old`;
    }

    patientFormatter(value: number, key: string, item: any): string {
        return `${item.patient.lastName}, ${item.patient.firstName}`;
    }

    dateFormatter(value: string): string {
        if (!value) return "";
        let date = Date.parse(value);
        return Intl.DateTimeFormat().format(date);
    }
}
