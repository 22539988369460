import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import DoctorQueue from "@/views/DoctorQueue.vue";

const routes: Array<RouteRecordRaw> = [
	{
        path: "/home",
        name: "Home",
        component: Home,
    },
	{
		path: "/about",
		name: "About",
		component: About,
	},
    {
        path: "/",
        name: "Doctor queue",
        component: DoctorQueue,
    },
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;
