
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import PDFObject from "pdfobject";
    import Axios, { AxiosResponse } from 'axios';
    import { Component, Prop, Vue, Watch } from "vue-facing-decorator";

    @Component({
        name: "ImageComponent",
    })
    export default class ImageComponent extends Vue {
        @Prop() private imageID!: number;
        @Prop() private escriptID!: number;
        @Prop() private rxNumber!: number;

        private imageSrc?: any = null;

        get pdfContainerID(): string {
            return 'pdf_' + this.imageID;
        }

        created(): void {
            console.log("%c Created ImageComponent", "color: green;");
            console.log(this.imageID);
            console.log(this.escriptID);
            if (this.imageID)
                this.fetchPrescriptionImageSource(`api/image/${this.imageID}`);
            else if (this.escriptID)
                this.fetchPrescriptionImageSource(`api/image/escript/${this.escriptID}`);
        }

        beforeDestroy(): void {
            console.log('%c Destroying ImageComponent', 'color: red');
        }

        @Watch('imageID')
        imageIDUpdated(value: number): void {
            if (value)
                this.fetchPrescriptionImageSource(`api/image/${value}`);
        }

        @Watch('escriptID')
        escriptIDUpdated(value: number): void {
            if (value) {
                this.fetchPrescriptionImageSource(`api/image/escript/${value}`);
            }
        }

        fetchPrescriptionImageSource(imageUrl?: string): void {
            if (imageUrl) {

                this.imageSrc = null;
                Axios.get(imageUrl).then((response: AxiosResponse<any>) => {
                    const url = response.request.responseURL;
                    Axios.get(url, { responseType: "blob" }).then(r => {
                        const objectUrl = URL.createObjectURL(r.data);
                        if (response.headers["content-type"] == "application/pdf") {
                            // noinspection SpellCheckingInspection
                            const options = {
                                pdfOpenParams: {
                                    pagemode: 'thumbs',
                                    scrollbar: 0,
                                    toolbar: 1,
                                    view: 'FitW',
                                },
                            };
                            PDFObject.embed(objectUrl, '#' + this.pdfContainerID, options);
                        } else {
                            this.imageSrc = objectUrl;
                        }
                        this.$nextTick(() => {
                            this.$forceUpdate();
                        });
                    });
                });
            }
        }

    }
