

    import HelloWorld from "@/components/HelloWorld.vue";
    import { Component, Vue } from "vue-facing-decorator"; // @ is an alias to /src

    @Component({
        components: {
            HelloWorld,
        },
    })
    export default class Home extends Vue {
    }
