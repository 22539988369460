import HasID from "./HasID";
import TrackedObject from "./TrackedObject";
import { Person } from "./Person";
import moment from "moment";
import { PhoneNumber } from "./PhoneNumber";
import { OptStatus } from "./OptStatus";

export interface Patient extends Person {
    dateOfBirth: Date;
    sex: string;

    emailAddress: string;
    phoneNumber: string;
    cellNumber: string;
    maritalStatus: string;
    height: number; // inches
    weight: number; // lbs
    residenceCode: string;
    pregnant?: Date;
    lactating?: Date;
    smoker?: Date;
    licenseState: string;
    licenseNumber: string;
    headOfHousehold?: number;
    primaryLanguage?: number; // some kind of code?? Backend has this as an int.
    alert: string; // Note to popup for patient anytime it is viewed
    childCaps: boolean;
    signatureRequired: boolean;
    nkda: boolean;
    defaultDelivery: number; // some kind of code? probably want to make an enum
    hipaaDocsReceived: Date;
    species: string;
    programs: Array<PatientProgram>;
    stripeCustomerId: string;
    ssn: string;
    cellId: number;
    phoneId: number;
    phone: PhoneNumber;
    cell: PhoneNumber;
    phoneOptStatus: OptStatus;
    cellOptStatus: OptStatus;
    patientAppUserName: string;
    primaryPrescriberId?: number;
}

export enum Language {
    English,
    Spanish,
}

export class Patient extends Person {
    constructor(obj?: Patient) {
        super();
        //this.id = 0;
        this.childCaps = true;
        this.nkda = true;
        this.signatureRequired = false;

        if (obj) Object.assign(this, obj);
    }

    toString(): string {
        let label = "";
        if (this.id && this.lastName) {
            const dobString =
                this && this.dateOfBirth
                    ? this.dateOfBirth.toString().slice(0, 10)
                    : "Unknown";
            label =
                "ID: " +
                this.id +
                " " +
                this.displayNamesForPerson() +
                " DOB:" +
                dobString +
                " Address: " +
                this.address1 +
                `, ${this.addressCity}, ${this.addressState}`;
        }
        return label;
    }

    get age(): number | null {
        if (this.dateOfBirth) return moment().diff(this.dateOfBirth, "years");
        else return null;
    }

    get languageName(): string {
        if (this.primaryLanguage === undefined || this.primaryLanguage === null)
            return "";

        return Language[this.primaryLanguage];
    }
    get phoneDisplay(): string {
        return (
            this.phone?.nationalFormat ??
            this.phoneNumber ??
            this.phone?.originalInput
        );
    }

    get cellDisplay(): string {
        return (
            this.cell?.nationalFormat ??
            this.cellNumber ??
            this.cell?.originalInput
        );
    }
}

export enum ResidenceCode {
    NotSpecified,
    PatientsHome,
    SkilledNursing,
    Nursing,
    AssistedLiving,
    CustodialCare,
    GroupHome,
    InpatientPsychiatric,
    Psychiatric,
    IntermediateCare,
    SubstanceAbuseTreatment,
    Hospice,
    PsychiatricTreatment,
    ComprehensiveRehab,
    HomelessShelter,
    CorrectionalInstitution,
}

export interface PatientProgram extends HasID, TrackedObject {
    programID: number;
    patientID: number;
    programName: string;
    identifier: string;
}
